import * as THREE from 'three'
import './style.css'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
import { Light } from 'three'
import { gsap } from "gsap";

/**
 * Base
 */
// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

/**
 * Textures
 */
const textureLoader = new THREE.TextureLoader()

const headColorTexture = textureLoader.load('/textures/head-test-1024.png')
const headAlphaTexture = textureLoader.load('/textures/head-test-1024-alpha.jpg')
const headNormalTexture = textureLoader.load('/textures/head-test-1024-normals.jpg')

// Background

const backgroundColorTexture = textureLoader.load('/textures/first_glow_bg.jpg')
const background = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(10*.83, 10, 100, 100),
    new THREE.MeshStandardMaterial({
        map: backgroundColorTexture,
		depthWrite: false,
		depthTest: true
    })
)
background.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(background.geometry.attributes.uv.array, 2))
background.position.set(0,0,0)
scene.add(background)

/**
 * boy
 */
// boy container
const boy = new THREE.Group()
scene.add(boy)

// body
const bodyColorTexture = textureLoader.load('/textures/boy_body.png')
const body = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(2.1, 2.1, 100, 100),
    new THREE.MeshStandardMaterial({
        map: bodyColorTexture,
        transparent: true,
    })
)
body.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(body.geometry.attributes.uv.array, 2))
body.receiveShadow = true
body.position.x = -1.2
body.position.y = -3.5
body.position.z = 0.75
boy.add(body)


// head
const head = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(3, 3, 100, 100),
    new THREE.MeshStandardMaterial({
        map: headColorTexture,
        transparent: true,
        normalMap: headNormalTexture,
		alphaMap: headAlphaTexture,
    })
)
head.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(head.geometry.attributes.uv.array, 2))
head.receiveShadow = true
head.position.x = -.4
head.position.y = -1.5
head.position.z = 1
boy.add(head)

function nodHead() {
	gsap.to(head.position, {
		y: '-=0.15',
		duration: 2,
		repeat: -1,
		yoyo: true,
		ease: "back.inOut(1.7)",
		yoyoEase: "back.inOut(1.7)"
	})
}

nodHead();


// Record
const recordColorTexture = textureLoader.load('/textures/record.png')
const record = new THREE.Mesh(
    new THREE.PlaneBufferGeometry(2.25, 1, 100, 100),
    new THREE.MeshStandardMaterial({
        map: recordColorTexture,
        transparent: true,
    })
)
record.geometry.setAttribute('uv2', new THREE.Float32BufferAttribute(record.geometry.attributes.uv.array, 2))
record.position.x = .5
record.position.y = -4
record.position.z = 1.1
boy.add(record)

// Vignette Right
const vrTexture = textureLoader.load('/textures/vignette_right.png')
const vlTexture = textureLoader.load('/textures/vignette_left.png')

const vigGeometry = new THREE.PlaneBufferGeometry(10*.83, 10)
const vrMaterial = new THREE.MeshLambertMaterial({
	map: vrTexture,
	transparent: true,
})
const vrMesh = new THREE.Mesh(
	vigGeometry,
	vrMaterial
)
vrMesh.position.set(2,0,1.2)

// Vignette Left
const vlMaterial = new THREE.MeshLambertMaterial({
	map: vlTexture,
	transparent: true,
})
const vlMesh = new THREE.Mesh(
	vigGeometry,
	vlMaterial
)
vlMesh.position.set(-2.2,0,1.4)

scene.add(vrMesh)
scene.add(vlMesh)

/**
 * Lights
 */
// Ambient light
const ambientLight = new THREE.AmbientLight('#b9d5ff', 0.3)
scene.add(ambientLight)

// Background
const bgLight = new THREE.PointLight(0x2895be, .7, 0)
bgLight.position.set(1, 1, 100)
scene.add(bgLight)

/**
 * Cloud
 */
// cloud container
const cloudGroup = new THREE.Group()
scene.add(cloudGroup)

const cloudTexture = textureLoader.load('/textures/smoke-1.png')
const cloudGeo = new THREE.PlaneBufferGeometry(4,4);
const cloudMaterial = new THREE.MeshLambertMaterial({
	map: cloudTexture,
	transparent: true,
});
cloudMaterial.color.set(0xf3a120)

const cloudParticles = []
for(let p=0; p<4; p++) {
	let cloud = new THREE.Mesh(cloudGeo,cloudMaterial)
	cloud.position.set(0.8, -2.4, p/2 + 1)
	cloud.rotation.z = Math.random() * Math.PI;
	cloud.material.opacity = 0.25;
	cloudParticles.push(cloud);
	cloudGroup.add(cloud);
}

/**
 * fireflies
 */
const fireflyGroup = new THREE.Group()
scene.add(fireflyGroup)

const firefly = createLight('#FFD361', .25)
fireflyGroup.add(firefly)

const firefly2 = createLight('#c84bc8', .4)
fireflyGroup.add(firefly2)

const firefly3 = createLight('#ff904c', .33)
fireflyGroup.add(firefly3)

const firefly4 = createLight('#4ce4ff', .15)
fireflyGroup.add(firefly4)


function createLight(color, radius) {
    let pointLight = new THREE.PointLight(color, 2, 10)
    pointLight.castShadow = true
    pointLight.shadow.camera.near = 1
    pointLight.shadow.camera.far = 10
    pointLight.shadow.bias = 0

    let geometry = new THREE.SphereBufferGeometry(radius, 24, 24)
    let material = new THREE.MeshToonMaterial({
		transparent: true,
		opacity: .1,
		color: color
    })
    let sphere = new THREE.Mesh(geometry, material)

	let insideGeometry = new THREE.SphereBufferGeometry(radius/4, 24, 24)
	let insideMaterial = new THREE.MeshToonMaterial({
		color: color
	})
	let insideSphere = new THREE.Mesh(insideGeometry, insideMaterial)
	insideSphere.position.set (0,0,0)


	sphere.position.set (0,0,0)
	sphere.add(insideSphere)
    pointLight.add(sphere)

    return pointLight
}

/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = 10
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
// Vertical limits
controls.minPolarAngle = Math.PI/2.7
controls.maxPolarAngle = Math.PI/1.7
// Horizontal limits
controls.minAzimuthAngle = -Math.PI / 8
controls.maxAzimuthAngle = Math.PI / 8
// Pan limits
controls.minDistance = 1
controls.maxDistance = 25

/**
 * Renderer
 */
 const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
	antialias: true,
	powerPreference: "high-performance"
})
renderer.sortObjects = false
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setClearColor('#000000')
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * Animate
 */
 const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // fireflys

	const fireflyAngle = - elapsedTime * 0.2
    firefly.position.x = Math.cos(fireflyAngle) * 5
    firefly.position.z = Math.abs(Math.sin(fireflyAngle) * 6 + 2)
    firefly.position.y = Math.sin(elapsedTime * 2.3) + Math.sin(elapsedTime * 1.5)

	const fireflyAngle2 = - elapsedTime * 0.12
    firefly2.position.x = Math.sin(fireflyAngle2) * 5
    firefly2.position.z = Math.abs(Math.sin(fireflyAngle2) * 5 + 1)
    firefly2.position.y = Math.cos(elapsedTime * .5) + Math.sin(elapsedTime * 2)

    const firefly3Angle = - elapsedTime * 0.18
    firefly3.position.x = Math.cos(firefly3Angle) * (7 + Math.sin(elapsedTime * 0.32))
    firefly3.position.z = Math.sin(firefly3Angle) * (9 + Math.sin(elapsedTime * 0.5))
    firefly3.position.y = Math.sin(elapsedTime * 4) + Math.sin(elapsedTime * 2.5)

	const firefly4Angle = - elapsedTime * 0.1
    firefly4.position.x = Math.cos(firefly4Angle) * (2 + Math.sin(elapsedTime * 0.5))
    firefly4.position.z = Math.cos(firefly4Angle) * (6 + Math.sin(elapsedTime * 0.25))
    firefly4.position.y = Math.sin(elapsedTime * .5) + Math.sin(elapsedTime * 1.5)

	// glow
	cloudParticles.forEach(p => {
		p.rotation.z -=0.002
	})

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()